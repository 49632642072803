*{
    
    scroll-behavior: smooth;
}
.category-scrollbar::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #697475;
    border-radius: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.box-shadow {
    box-shadow: 0 4px 4px 0 #00000025;
}

.hoverVisible {
    transition: all 0.25s ease-in-out;
}

.addressCard:hover .hoverVisible {
    opacity: 100;
}

.react-select__value-container{
    padding: 0px !important;
}
.css-1xc3v61-indicatorContainer{
    padding: 0px !important;
}
.css-tj5bde-Svg{
    width: 14px;
    color: black;
}